import { notification } from 'antd';
import ApiUtils from '../../helpers/apiUtils';

export const login = userData => {
  return {
    type: 'LOGIN',
    ...userData,
  };
};
export const logout = () => ({
  type: 'LOGOUT',
});

export const update = userData => ({
  type: 'UPDATE',
  ...userData,
});

export const forceRefreshTokenDetails = () => {
  const api = new ApiUtils(true, true, true);

  return async (dispatch, getState) => {
    let { user } = getState().auth;

    let decryptedToken = user.token;
    if (!decryptedToken) {
      return;
    }
    try {
      const res = await api.getTokenLogin(decryptedToken);
      if (res.status === 200) {
        if (res.data) {
          dispatch(
            update({
              user: {
                ...user,
                // Since other flag cause render issues, we are not updating them
                manual_claim_entry_enabled: res.data.manual_claim_entry_enabled,
                office_enable: res.data.office_enable,
                trial_expiry_date: res.data.trial_expiry_date,
                on_trial: res.data.on_trial,
              },
            })
          );
        } else {
          notification.error({
            icon: <i className="fas fa-times-circle"></i>,
            className: 'error-notification',
            message: 'Error',
            description: res.data.message,
          });
        }
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        setIsError(true);
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    }
  };
};
