import { Menu } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { sendBirdSelectors, withSendBird } from 'sendbird-uikit';

import { useDispatch, useSelector } from 'react-redux';
import ApiUtils from '../../../helpers/apiUtils';
import { login } from '../../../redux/actions/authAction';
import { QuickLinkType } from '../../../redux/actions/quickLinkAction';
import { StaticPageType } from '../../../redux/actions/staticPageAction';
import { UserQuickLinkType } from '../../../redux/actions/userQuickLinkAction';
import { useChurnZero } from '../../auth/churnZero';
import { INTERVALS } from '../../../constants/common';

const { SubMenu } = Menu;

function MainSidebar(props) {
  const api = useRef(new ApiUtils(true, true, true));
  const dispatchCall = useDispatch();

  const quick_links = useSelector(state => state.quickLink.QuickLinkType);
  const user_quick_links = useSelector(state => state.userQuickLink.UserQuickLinkType);
  let user_role = useSelector(state => state.auth.user.user_role);
  let third_party_user_type = useSelector(state => state.auth.user.third_party_user);
  let login_user_role_type = useSelector(state => state.auth.user.login_user_role_type);
  const isEventsEnable = useSelector(state => state?.auth?.user?.events_enable);
  // const userID = useSelector(state => state.auth.user.id);
  const user = useSelector(state => state.auth.user);
  const [showDot, setShowDot] = useState({ visible: false, count: 0 });
  const today = new Date();
  const year = today.getFullYear();
  const churnZeroData = useChurnZero();

  const sdk = props?.sdk;

  React.useEffect(
    function () {
      async function getUnreadCount() {
        const unreadCount =
          sdk && sdk?.getTotalUnreadMessageCount && (await sdk?.getTotalUnreadMessageCount());
        if (unreadCount && unreadCount > 0) {
          setShowDot({ visible: true, count: unreadCount });
        } else {
          setShowDot({ visible: false, count: 0 });
        }
      }
      var interval = setInterval(function () {
        getUnreadCount();
      }, INTERVALS.ONE_SECOND);
      return function () {
        clearInterval(interval);
      };
    },
    [sdk]
  );

  const getNewSSO = async () => {
    try {
      // track event using churnZero
      if (churnZeroData.churnInfo) {
        churnZeroData.churnInfo.trackEvent(`Support`, `User clicked on "Support" CTA`);
      }
      const res = await api.current.getNewSSO();
      if (res.status === 200) {
        dispatchCall(
          login({
            ...user,
            zendesk_sso_url: res && res.data && res.data.url,
          })
        );
        window.open(`${res && res.data && res.data.url}`, '_blank');
      }
    } catch (err) {
      console.log(err);
    }
  };

  let routes = [];
  if (!third_party_user_type) {
    routes = [
      {
        to: '/global-claim',
        icon: 'fas fa-gavel',
        title: login_user_role_type === 'law_firm' ? 'Matters' : 'Claims',
      },
      {
        to: '/events',
        icon: 'fas fa-calendar-check',
        title: 'Milestones & Events',
      },
      {
        to: '/global-task',
        icon: 'fal fa-tasks',
        title: 'Tasks & Requests',
      },
      {
        to: '/global-contact',
        icon: 'fas fa-users',
        title: 'Contacts',
      },
      {
        to: '/messenger',
        icon: 'fas fa-comments-alt',
        title: 'Messenger',
      },
      {
        to: '/shortcut',
        icon: 'fas fa-share-square',
        title: 'Shortcut',
      },
      {
        to: 'https://claimdeck.zendesk.com/hc/en-us',
        icon: 'fas fa-question-circle',
        title: 'Support',
      },
    ];
    if (!isEventsEnable) {
      routes.splice(1, 1);
    }
  } else {
    routes = [
      {
        to: '/global-claim',
        icon: 'fas fa-gavel',
        title: login_user_role_type === 'law_firm' ? 'Matters' : 'Claims',
      },
      {
        to: '/messenger',
        icon: 'fas fa-comments-alt',
        title: 'Messenger',
      },
      {
        to: 'https://claimdeck.zendesk.com/hc/en-us',
        icon: 'fas fa-question-circle',
        title: 'Support',
      },
    ];
  }

  const getSelectedKey = pathname => {
    for (var index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (pathname.includes(element.key)) {
        return element.key;
      }
    }
  };

  const getAllQuickLinks = async () => {
    if (!third_party_user_type) {
      try {
        const params = {
          limit: 10000000,
          offset: 0,
        };
        const res = await api.current.getAllQuickLinks(params);
        if (res.status === 200) {
          let quick_link_data = res.data.results;
          dispatchCall(
            QuickLinkType({
              quick_link_data,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const stableHandler = useCallback(getAllQuickLinks, []);

  useEffect(() => {
    stableHandler();
  }, [stableHandler]);

  const getAllUserQuickLinks = async () => {
    if (!third_party_user_type) {
      try {
        const res = await api.current.getAllUserQuickLinks();
        if (res.status === 200) {
          let quick_link_data = res.data.results;
          dispatchCall(
            UserQuickLinkType({
              quick_link_data,
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const userLinkStableHandler = useCallback(getAllUserQuickLinks, []);

  useEffect(() => {
    userLinkStableHandler();
  }, [userLinkStableHandler]);

  useEffect(() => {
    userLinkStableHandler();
  }, [userLinkStableHandler]);

  return (
    <React.Fragment>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[getSelectedKey(props?.location?.pathname)]}
        inlineCollapsed={props.collapsed}
      >
        {routes &&
          routes.map((element, index) => (
            <div
              className={`ant-menu-item ant-menu-item-only-child side-class ${
                element.class ? element.class : ''
              }`}
              key={index}
            >
              {/* <Menu.Item key={element.title} className={element.class}> */}
              {element.title === 'Shortcut' ? (
                <SubMenu
                  key="quicklink"
                  popupClassName="submenu-quicklink"
                  className="quicklink"
                  title={
                    <div className="flex ai-center flex-start diffmenu">
                      <p className="mb-0">
                        <i className="fas fa-share-square"></i>
                      </p>
                      <span>Quick Link</span>
                      {user_role === 'carrier_admin' || user_role === 'law_firm_admin' ? (
                        <p className="mb-0">
                          {' '}
                          <Link to="/admin-setting">
                            <i className="fas fa-pencil"></i>
                          </Link>
                        </p>
                      ) : (
                        <p className="mb-0">
                          {' '}
                          <Link to="/account-setting">
                            <i className="fas fa-pencil"></i>
                          </Link>
                        </p>
                      )}
                    </div>
                  }
                >
                  {user_quick_links &&
                    user_quick_links.length > 0 &&
                    !props.collapsed &&
                    user_quick_links.map(item => (
                      <Menu.Item key={item.id}>
                        <a target="_blank" rel="noopener noreferrer" href={item.url}>
                          {item.name}
                        </a>
                      </Menu.Item>
                    ))}

                  {quick_links &&
                    quick_links.length > 0 &&
                    !props.collapsed &&
                    quick_links.map(item => (
                      <Menu.Item key={item.id}>
                        <a target="_blank" rel="noopener noreferrer" href={item.url}>
                          {item.name}
                        </a>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : element.title === 'Messenger' ? (
                <Link to={element.to}>
                  <div className="profile-wrap xs-profile pos-relative">
                    {showDot?.visible && (
                      <div
                        className="ndot ncount sm-dot mr-10"
                        style={{
                          left: '10px',
                          top: '-11px',
                        }}
                      >
                        {showDot?.count > 99 ? `99+` : showDot?.count}
                      </div>
                    )}
                  </div>
                  <span className={`${element.icon} sidebar-icon-span`} />
                  <span>{element.title}</span>
                </Link>
              ) : element.title === 'Support' ? (
                <button onClick={() => getNewSSO()}>
                  <span className={`${element.icon} sidebar-icon-span`} />
                  <span>{element.title}</span>
                </button>
              ) : (
                <Link to={element.to}>
                  <span className={`${element.icon} sidebar-icon-span`} />
                  <span>{element.title}</span>
                </Link>
              )}
              {/* </Menu.Item> */}
            </div>
          ))}
      </Menu>
      <div className="footer-links">
        <span
          onClick={() => {
            let currentState = {
              isVisible: true,
              slug: 'privacy-policy',
            };
            dispatchCall(
              StaticPageType({
                ...currentState,
              })
            );
          }}
        >
          Privacy Policy
        </span>
        <span
          onClick={() => {
            let currentState = {
              isVisible: true,
              slug: 'terms-of-service',
            };
            dispatchCall(
              StaticPageType({
                ...currentState,
              })
            );
          }}
        >
          Terms of Service
        </span>
        <span>&copy;{year}</span>
      </div>
    </React.Fragment>
  );
}

// export default withRouter(MainSidebar);

export default withRouter(
  withSendBird(MainSidebar, state => {
    return {
      sdk: sendBirdSelectors.getSdk(state),
    };
  })
);
