//  ----------------- REGEX ---------------------
export const ZIP_REGEX = /^[0-9A-Za-z]+$/;
export const INVALID_ZIP_CODE_MESSAGE =
  'Zipcode must match the required pattern. ex-78ABCIO. No special characters are allowed.';

// eslint-disable-next-line
export const EMAIL_REGEX =
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*')@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const LINKEDIN_REGEX =
  /^(https?:\/\/)(?:www\.)?linkedin.com\/(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const INVALID_LINKEDIN_URL_MESSAGE = 'Please add valid LinkedIn URL';

export const URL_REGEX =
  /^((http|ftp|https|www|smtp):\/\/)[-a-zA-Z0-9@%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_.~#?&//=]*)$/;

export const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3,}\)?[\s.-]?\d{3,}[\s.-]?\d{3,}$/;
export const MAX_DIGIT_VALUE = 100000000000000000.0;

export const INTERVALS = {
  ONE_SECOND: 1000, // 1 sec
  THREE_SECONDS: 3000, // 3 sec
  TEN_SECONDS: 10000, // 10 sec
  THIRTY_SECONDS: 30000, // 30 sec
  ONE_MINUTE: 60000, // 1 min
  THREE_MINUTES: 180000, // 3 min
  FIVE_MINUTES: 300000, // 5 min
};
