import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Input, Menu, Dropdown, Button, notification, Spin } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from 'react-smooth-scrollbar';
import { useLocation } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import Async from 'react-code-splitting';
import { withSendBird, sendBirdSelectors, Channel } from 'sendbird-uikit';

// assets
import logo from '../../../assets/images/logo.png';
import DefaultImage from '../../../assets/images/no_image.png';
import Capitalize from '../../../shared_components/pipes/capitalize';
import { GlobalContact } from '../../../redux/actions/globalContactAction';
import loader from '../../../assets/images/spinner.gif';
import triangleexclamation from '../../../assets/images/triangle-exclamation-solid.svg';

// custom imports
import { forceRefreshTokenDetails, logout } from '../../../redux/actions/authAction';
import { FormModelType } from '../../../redux/actions/formModelTypeAction';
import ApiUtils from '../../../helpers/apiUtils';
import TableLoader from '../../../shared_components/loader/table-loader';
import ComponentLoader from '../../../shared_components/loader/component_loader';
import SelectDrawer from '../../main_content/global_notification/select_drawer';
import { notificationType } from '../../../redux/actions/globalNotificationAction';
import { messengerChat } from '../../../redux/actions/messengerChatAction';
import { globalSearch } from '../../../redux/actions/globalSearchAction';
import { localSearch } from '../../../redux/actions/localSearchAction';
import { login } from '../../../redux/actions/authAction';
import { useChurnZero } from '../../auth/churnZero';
import churnZero from '../../../shared_components/pipes/churnZero_mapping';
import { filterByDomain } from '../../../shared_components/pipes/churnZero_filters';
import { downloadFile } from '../../../helpers/commonHelper';
import moment from 'moment';
import { INTERVALS } from '../../../constants/common';
// modals
const CreateTaskModal = props => (
  <Async
    load={import('../../main_content/tasks/sub-comp/create_task_modal')}
    componentProps={props}
  />
);
const AddContact = props => (
  <Async
    load={import('../../../components/main_content/global_contacts/add_contact')}
    componentProps={props}
  />
);
const AddCompany = props => (
  <Async
    load={import('../../../components/main_content/global_contacts/add_company')}
    componentProps={props}
  />
);
const CreateEventModal = props => (
  <Async
    load={import('../../../components/main_content/events/create_event_modal')}
    componentProps={props}
  />
);

function MainHeader(props) {
  const api = useRef(new ApiUtils(true, true, true));
  let isContactAdded = useSelector(state => state.globalContact.isContactAdded);

  const { claim_count, contact_count, document_count } = useSelector(state => state.globalSearch);
  const location = useLocation();
  const dispatchCall = useDispatch();

  // local state variables
  const [postsPerPageClaim, setPostsPerPageClaim] = useState(3);
  const [postsPerPageContact, setPostsPerPageContact] = useState(3);
  const [postsPerPageDocument, setPostsPerPageDocument] = useState(3);
  // const [totalCount, setTotalCount] = useState(3);
  const [IsNotificationDrawerShow, setIsNotificationDrawerShow] = useState(false);
  const search_keyword = useSelector(state => state.globalSearch.search_keyword);

  const [searchKeyword, setSearchKeyword] = useState(search_keyword);
  // var matchKeyword = '\\b' + searchKeyword + '\\b';

  const [timer, setTimer] = useState();
  const [openMenuSearchFlag, setOpenMenuSearchFlag] = useState(false);

  // search variables
  const [claimSearchData, setClaimSearchData] = useState();
  const [contactSearchData, setContactSearchData] = useState();
  const [documentSearchData, setDocumentSearchData] = useState();

  // recent view variables
  const [claimRecentSearchData, setClaimRecentSearchData] = useState();
  const [contactRecentSearchData, setContactRecentSearchData] = useState();
  const [documentRecentSearchData, setDocumentRecentSearchData] = useState();

  // task and request variables
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [metaData, setMetaData] = useState();
  const [authoritySelected, setAuthoritySelected] = useState(false);

  // global contact variables
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);

  // global background export variables
  const [inProgressBGTasks, setInProgressBGTasks] = useState(0);

  // create event modal variables
  const [showEventModal, setShowEventModal] = useState(false);

  // redux variables
  const first_name = useSelector(state => state.auth.user.first_name);
  const last_name = useSelector(state => state.auth.user.last_name);
  const company_name = useSelector(state => state.auth.user.company_name);
  const { on_trial, trial_expiry_date } = useSelector(state => state.auth.user);
  const trial_days = moment(moment(new Date(trial_expiry_date)).format('MM-DD-yyyy')).diff(
    moment().format('MM-DD-yyyy'),
    'days'
  );
  const glabal_notification_available = useSelector(
    state => state?.notification?.IsGlabalNotificationAvailable
  );
  const messenger_group_url = useSelector(state => state?.messengerChat?.messenger_group_url);

  let user_type_data = useSelector(state => state?.auth?.user?.front_org_type);
  let user_type = useSelector(state => state?.auth?.user?.company_org_type);
  let user_role = useSelector(state => state?.auth?.user?.user_role);
  let login_user_role_type = useSelector(state => state?.auth?.user?.login_user_role_type);
  let profile_pic = useSelector(state => state.auth?.user?.profile_pic);
  let third_party_user_type = useSelector(state => state?.auth?.user?.third_party_user);
  const LoginUserInfo = useSelector(state => state?.auth?.user);
  const login_user_email = useSelector(state => state?.auth?.user?.primary_email_address);
  const churnZeroData = useChurnZero();
  const { REACT_APP_CHURNZERO_ALLOWED_EMAILS = '' } = process.env;

  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [menuSearchDataLoading, setMenuSearchDataLoading] = useState(false);
  const [menuRecentSearchDataLoading, setMenuRecentSearchDataLoading] = useState(false);

  const [showDot, setShowDot] = useState(false);
  const ShowUserTypeDrop =
    location?.pathname?.includes('overview') ||
    location?.pathname?.includes('numbers') ||
    location?.pathname?.includes('contacts') ||
    location?.pathname?.includes('documents') ||
    location?.pathname?.includes('tasks') ||
    location?.pathname?.includes('claimdeck_ai');

  const sdk = props?.sdk;
  React.useEffect(
    function () {
      async function getUnreadCount() {
        const channelData = await sdk?.GroupChannel?.getChannel(messenger_group_url);

        if (channelData && channelData?.unreadMessageCount > 0) {
          setShowDot(true);
        } else {
          setShowDot(false);
        }
      }
      var interval = setInterval(function () {
        if (messenger_group_url && ShowUserTypeDrop) {
          getUnreadCount();
        }
      }, INTERVALS.ONE_SECOND);
      return function () {
        clearInterval(interval);
      };
    },
    [sdk, messenger_group_url]
  );

  // get initial data
  const getNotifications = async () => {
    try {
      const res = await api.current.notificationStatus();
      if (res.status === 200) {
        let notificationData = {};
        notificationData = {
          global_notification: res.data ? res.data.global : false,
          matter: res.data ? res.data.matter : false,
          task_request: res.data ? res.data.task_request : false,
          system: res.data ? res.data.system : false,
          event: res.data ? res.data.event : false,
        };
        dispatchCall(notificationType(notificationData));
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        icon: <i className="fas fa-times-circle"></i>,
        className: 'error-notification',
        message: 'Error',
        description: 'Something went wrong',
      });
    } finally {
    }
  };
  const stableHandlerNotification = useCallback(getNotifications, []);

  useEffect(() => {
    stableHandlerNotification();
  }, [stableHandlerNotification]);

  const updateChurnZeroValue = async () => {
    if (
      (LoginUserInfo &&
        filterByDomain(LoginUserInfo.primary_email_address) &&
        !LoginUserInfo.company_name.toLowerCase().includes('test')) ||
      (LoginUserInfo.company_name.toLowerCase().includes('test') &&
        REACT_APP_CHURNZERO_ALLOWED_EMAILS.split(',').includes(LoginUserInfo.primary_email_address))
    ) {
      const tempChurnZero = await churnZero(
        LoginUserInfo?.company_id,
        LoginUserInfo?.primary_email_address
      );
      churnZeroData.login(tempChurnZero);
    }
  };

  const stableHandleChurnZero = useCallback(updateChurnZeroValue, []);

  const getUserRole = async () => {
    try {
      const res = await api.current.getUserRole();
      if (res.status === 200) {
        dispatchCall(
          login({
            ...LoginUserInfo,
            user_role: res?.data?.user_role,
          })
        );
      }
    } catch (err) {
      console.log(err);
      notification.error({
        icon: <i className="fas fa-times-circle"></i>,
        className: 'error-notification',
        message: 'Error',
        description: 'Something went wrong',
      });
    } finally {
    }
  };
  const stableHandlerRole = useCallback(getUserRole, [user_role]);

  useEffect(() => {
    stableHandlerRole();
  }, [stableHandlerRole]);

  useEffect(() => {
    if (!churnZeroData.churnInfo) {
      stableHandleChurnZero();
    }
  }, [stableHandleChurnZero, churnZeroData.churnInfo]);

  // get initial data
  const getRecentSearchData = async () => {
    setMenuRecentSearchDataLoading(true);
    try {
      let params = {
        limit: 3,
        offset: 0,
        section: 'claim,document,contact',
      };
      const res = await api.current.getRecentSearchData(params);
      if (res.status === 200) {
        if (res.data) {
          let temp_claim = [];
          let temp_contact = [];
          let temp_doc = [];

          if (res.data.claims && res.data.claims.data && res.data.claims.data.length > 0) {
            res.data.claims.data.forEach(element => {
              temp_claim.push(element);
            });
          }
          if (res.data.contacts && res.data.contacts.data && res.data.contacts.data.length > 0) {
            res.data.contacts.data.forEach(element => {
              temp_contact.push(element);
            });
          }
          if (res.data.documents && res.data.documents.data && res.data.documents.data.length > 0) {
            res.data.documents.data.forEach(element => {
              temp_doc.push(element);
            });
          }
          setClaimRecentSearchData(temp_claim);
          setContactRecentSearchData(temp_contact);
          setDocumentRecentSearchData(temp_doc);
          setMenuRecentSearchDataLoading(false);
        }
      } else {
        setMenuRecentSearchDataLoading(false);
      }
    } catch (err) {
      console.log(err);
      setMenuRecentSearchDataLoading(false);
      notification.error({
        icon: <i className="fas fa-times-circle"></i>,
        className: 'error-notification',
        message: 'Error',
        description: 'Something went wrong',
      });
    }
  };

  const setDefaultSearchKey = () => {
    dispatchCall(
      globalSearch({
        search_keyword: '',
        total_count: 0,
        claim_count: 0,
        contact_count: 0,
        document_count: 0,
      })
    );
    setSearchKeyword('');
  };

  const stableHandlerRecent = useCallback(setDefaultSearchKey, []);

  useEffect(() => {
    stableHandlerRecent();
  }, [stableHandlerRecent]);

  const userFormTypeHandleChange = event => {
    let key = event.key;
    if (key === '1' || key === '2') {
      let isVisible = false;
      let isClaimVisible = false;
      let value = 'Claim';
      if (key === '1') {
        isVisible = true;
        value = 'Matter';
      }
      if (key === '2') {
        isClaimVisible = true;
        value = 'Claim';
      }
      let currentState = {
        isVisible: isVisible,
        slug: value,
        isClaimVisible: isClaimVisible,
      };
      dispatchCall(FormModelType(currentState));
    } else if (key === '3' || key === '4') {
      if (!third_party_user_type) {
        getAllTaskMetaData();
      }
      if (key === '3') {
        setAuthoritySelected(true);
      } else {
        setAuthoritySelected(false);
      }
      setShowCreateTaskModal(true);
    } else if (key === '5' || key === '6' || key === '7') {
      if (key === '5') {
        setShowAddPerson(true);
      } else if (key === '7') {
        setShowEventModal(true);
      } else {
        setShowAddCompany(true);
      }
    }
  };

  const getGlobalSearchData = async (paraSearchKeyword, paraPage) => {
    setMenuSearchDataLoading(true);
    try {
      let params = {
        limit: paraPage ? 10 : 3,
        offset: 0,
        section: 'claim,document,contact',
        q: paraSearchKeyword,
      };
      const res = await api.current.getGlobalSearchData(params);
      if (res.status === 200) {
        if (res.data) {
          dispatchCall(
            globalSearch({
              search_keyword: paraSearchKeyword,
              total_count: res?.data?.total_count !== 0 ? res?.data?.total_count : 0,
              claim_count: res?.data?.total_count !== 0 ? res?.data?.claims?.count : 0,
              contact_count: res?.data?.total_count !== 0 ? res?.data?.contacts?.count : 0,
              document_count: res?.data?.total_count !== 0 ? res.data?.documents?.count : 0,
            })
          );

          dispatchCall(
            localSearch({
              search_keyword: paraSearchKeyword,
            })
          );

          // track event using churnZero
          try {
            if (churnZeroData.churnInfo && filterByDomain(login_user_email)) {
              churnZeroData.churnInfo.trackEvent('Global Search', 'User clicked search CTA', 1);
            }
          } catch (err) {
            console.log(err);
          }

          let temp_claim = [];
          let temp_contact = [];
          let temp_doc = [];

          if (res?.data?.claims && res?.data?.claims?.data) {
            if (res.data.claims.data.length > 0) {
              res.data.claims.data.forEach(element => {
                let matchObj = {};
                Object.entries(element).forEach(([key, value]) => {
                  if (key !== 'claim_name' && key !== 'claim_number' && key !== 'id') {
                    matchObj[key] = value;
                  }
                });
                temp_claim.push({
                  ...element,
                  matchObj: matchObj,
                });
              });
            }
          }
          if (res?.data?.contacts && res?.data?.contacts?.data) {
            if (res.data.contacts.data.length > 0) {
              res.data.contacts.data.forEach(element => {
                let matchObj = {};
                Object.entries(element).forEach(([key, value]) => {
                  if (
                    key !== 'full_name' &&
                    key !== 'id' &&
                    key !== 'primary_email_address' &&
                    key !== 'first_name' &&
                    key !== 'last_name' &&
                    key !== 'full_name' &&
                    key !== 'matter_id'
                  ) {
                    matchObj[key] = value;
                  }
                });
                temp_contact.push({
                  ...element,
                  matchObj: matchObj,
                });
              });
            }
          }
          if (res?.data?.documents && res?.data?.documents?.data) {
            if (res.data.documents.data.length > 0) {
              res.data.documents.data.forEach(element => {
                let matchObj = {};
                Object.entries(element).forEach(([key, value]) => {
                  if (
                    key !== 'name' &&
                    key !== 'id' &&
                    key !== 'matter' &&
                    key !== 'matter_id' &&
                    key !== 'is_folder' &&
                    key !== 'is_link' &&
                    key !== 'file_link' &&
                    key !== 'file' &&
                    key !== 'matter_id' &&
                    key !== 'folder'
                  ) {
                    matchObj[key] = value;
                  }
                });
                temp_doc.push({
                  ...element,
                  matchObj: matchObj,
                });
              });
            }
          }
          setClaimSearchData(temp_claim);
          setContactSearchData(temp_contact);
          setDocumentSearchData(temp_doc);

          // setTimeout(() => {
          //   setMenuSearchDataLoading(false);
          // }, 5000);
        } else {
          // setTimeout(() => {
          //   setMenuSearchDataLoading(false);
          // }, 2000);
        }
      } else {
        // setTimeout(() => {
        //   setMenuSearchDataLoading(false);
        // }, 2000);
      }
    } catch (err) {
      console.log(err);
      // setMenuSearchDataLoading(false);
    } finally {
      setMenuSearchDataLoading(false);
    }
  };
  // get initial data
  const apiLogoutCall = async () => {
    setTableDataLoading(true);
    try {
      const res = await api.current.logOutCall();
      if (res.status === 200) {
        dispatchCall(logout());
        dispatchCall(messengerChat(''));
        if (filterByDomain(login_user_email)) {
          churnZeroData.logout();
        }
        notification.success({
          icon: <i className="fas fa-check-circle"></i>,
          className: 'success-notification',
          message: 'Success',
          description: `You've logged out of your account.`,
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
        setTableDataLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTableDataLoading(false);
      notification.error({
        icon: <i className="fas fa-times-circle"></i>,
        className: 'error-notification',
        message: 'Error',
        description: 'Something went wrong',
      });
    } finally {
    }
  };

  const getAllTaskMetaData = async () => {
    try {
      const res = await api.current.getTaskMetaData();
      if (res.status === 200) {
        setMetaData(res.data);
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Page Not Found',
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    }
  };

  // Menu Logout/Setting
  const menu = (
    <Menu className="hedaer_menu">
      <Menu.Item key="1">
        <i className="fas fa-user-cog"></i> &nbsp;
        <Link to={`/account-setting/myAccount`}>Account Settings</Link>
      </Menu.Item>
      {(user_role === 'carrier_admin' || user_role === 'law_firm_admin') &&
        !third_party_user_type && (
          <Menu.Item key="2">
            <i className="fas fa-user-shield"></i> &nbsp;
            <Link to={`/admin-setting`}>Admin Settings</Link>
          </Menu.Item>
        )}
      <Menu.Item
        key="3"
        onClick={() => {
          apiLogoutCall();
        }}
      >
        <i className="far fa-sign-out"></i> &nbsp; Log Out
      </Menu.Item>
    </Menu>
  );

  // menu for task, request, claim, matter and contact create
  const menuGlobalActions = (
    <Menu onClick={userFormTypeHandleChange}>
      {LoginUserInfo.manual_claim_entry_enabled &&
        (user_type === 'law_firm' ? (
          <Menu.Item key="1">Matter</Menu.Item>
        ) : (
          <Menu.Item key="2">Claim</Menu.Item>
        ))}
      {login_user_role_type === 'law_firm' ? (
        <>
          <Menu.Item key="4">Task</Menu.Item>
          <Menu.Item key="3">Request</Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="4">Task</Menu.Item>
        </>
      )}
      {LoginUserInfo.events_enable && <Menu.Item key="7">Event</Menu.Item>}
    </Menu>
  );

  const onCloseNotificationDrawer = flag => {
    setIsNotificationDrawerShow(false);
  };

  //global search here
  const menuGlobalSearch = (
    <Menu className="component-loader-parent">
      {(menuSearchDataLoading || menuRecentSearchDataLoading) && (
        <ComponentLoader className="component-loader" />
      )}
      {searchKeyword &&
        claimSearchData &&
        contactSearchData &&
        documentSearchData &&
        claimSearchData.length === 0 &&
        contactSearchData.length === 0 &&
        documentSearchData.length === 0 && (
          <div className="no-data">
            <i className="fa fa-search no-data-icon" aria-hidden="true"></i>
            <div>No results found</div>
          </div>
        )}
      <Menu.Item key="1">
        {searchKeyword &&
          claimSearchData &&
          contactSearchData &&
          documentSearchData &&
          (claimSearchData.length > 0 ||
            contactSearchData.length > 0 ||
            documentSearchData.length > 0) && (
            <div className="search-list">
              <Scrollbar
                damping={5}
                thumbMinSize={80}
                syncCallbacks={true}
                alwaysShowTracks={true}
                continuousScrolling={true}
                style={{ maxHeight: '480px', width: '516px', overflowX: 'hidden' }}
                className="scrollblock grey-scroll md-scroll"
              >
                {/*  Claims/Contacts/Documents */}
                <div className="search-block">
                  <small>{login_user_role_type === 'law_firm' ? 'Matters' : 'Claims'}</small>
                  {/* list of claims */}
                  {claimSearchData &&
                    claimSearchData.length > 0 &&
                    postsPerPageClaim === 3 &&
                    claimSearchData.map((item, index) => (
                      <>
                        {index < 3 && (
                          <div className="search-row">
                            <div className="srow">
                              <i className="fas fa-gavel"></i>
                              <Link
                                to={`/claim/${item.id}/overview`}
                                className="primary-link grey-link"
                              >
                                <div className="sbind">
                                  <Highlighter
                                    highlightClassName="highlightMatch"
                                    searchWords={[searchKeyword]}
                                    autoEscape={true}
                                    textToHighlight={item['claim_name'] ? item['claim_name'] : ''}
                                  />
                                  &nbsp;
                                  <span className="htext">
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={
                                        item['claim_number'] ? item['claim_number'] : ''
                                      }
                                    />
                                  </span>
                                </div>
                              </Link>
                            </div>
                            <div className="stags">
                              {item.matchObj &&
                                Object.keys(item.matchObj).length !== 0 &&
                                Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                                  if (keyIndex < 3) {
                                    return (
                                      <span className="stag-li">
                                        {Capitalize(keyName.replace(/_/g, ' '))}:
                                        {keyName !== 'claim_overview' &&
                                          keyName !== 'current_resolution_strategy' &&
                                          keyName !== 'claim_snopsis' && (
                                            <Highlighter
                                              highlightClassName="highlightMatch"
                                              searchWords={[searchKeyword]}
                                              autoEscape={true}
                                              textToHighlight={item[keyName]}
                                            />
                                          )}
                                        {(keyName === 'claim_overview' ||
                                          keyName === 'current_resolution_strategy' ||
                                          keyName === 'claim_snopsis') && (
                                          <>
                                            &nbsp; ...
                                            <div className="highlightMatch">{searchKeyword}</div>
                                            ...
                                          </>
                                        )}
                                      </span>
                                    );
                                  }
                                  return '';
                                })}
                              {Object.keys(item.matchObj).length > 4 && (
                                <span className="stag-li">
                                  +{Object.keys(item.matchObj).length - 3}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  {claimSearchData &&
                    claimSearchData.length > 0 &&
                    postsPerPageClaim !== 3 &&
                    claimSearchData.map((item, index) => (
                      <div className="search-row" key={index}>
                        <div className="srow">
                          <i className="fas fa-gavel"></i>
                          <Link
                            to={`/claim/${item.id}/overview`}
                            className="primary-link grey-link"
                          >
                            <div className="sbind">
                              <Highlighter
                                highlightClassName="highlightMatch"
                                searchWords={[searchKeyword]}
                                autoEscape={true}
                                textToHighlight={item['claim_name']}
                              />
                              &nbsp;
                              <span className="htext">
                                <Highlighter
                                  highlightClassName="highlightMatch"
                                  searchWords={[searchKeyword]}
                                  autoEscape={true}
                                  textToHighlight={item['claim_number']}
                                />
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="stags">
                          {item.matchObj &&
                            Object.keys(item.matchObj).length !== 0 &&
                            Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                              if (keyIndex < 3) {
                                return (
                                  <span className="stag-li">
                                    {Capitalize(keyName.replace(/_/g, ' '))}:
                                    {keyName !== 'claim_overview' &&
                                      keyName !== 'current_resolution_strategy' &&
                                      keyName !== 'claim_snopsis' && (
                                        <Highlighter
                                          highlightClassName="highlightMatch"
                                          searchWords={[searchKeyword]}
                                          autoEscape={true}
                                          textToHighlight={item[keyName]}
                                        />
                                      )}
                                    {(keyName === 'claim_overview' ||
                                      keyName === 'current_resolution_strategy' ||
                                      keyName === 'claim_snopsis') && (
                                      <>
                                        &nbsp; ... &nbsp;
                                        <div className="highlightMatch">{searchKeyword}</div>
                                        &nbsp;...
                                      </>
                                    )}
                                  </span>
                                );
                              }
                              return '';
                            })}
                          {Object.keys(item.matchObj).length > 4 && (
                            <span className="stag-li">
                              +{Object.keys(item.matchObj).length - 3}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  <div>
                    {postsPerPageClaim === 3 &&
                      claim_count > 3 &&
                      // &&
                      claimSearchData && (
                        // && claimSearchData.length > 3
                        <span
                          className="primary-link flex flex-start ai-center sm-link ml-15"
                          onClick={() => {
                            setPostsPerPageClaim(10);
                            if (claimSearchData?.length <= 3) {
                              getGlobalSearchData(searchKeyword, true);
                            }
                          }}
                        >
                          Show More <i className="fas fa-chevron-down"></i>
                        </span>
                      )}
                    {postsPerPageClaim !== 3 && (
                      <span
                        className="primary-link flex flex-start ai-center sm-link ml-15"
                        onClick={() => {
                          setPostsPerPageClaim(3);
                        }}
                      >
                        Show Less <i className="fas fa-chevron-up"></i>
                      </span>
                    )}
                  </div>
                </div>
                {/* Contacts */}
                <div className="search-block">
                  <small>Contacts</small>
                  {/* list of contacts */}
                  {contactSearchData &&
                    contactSearchData.length > 0 &&
                    postsPerPageContact === 3 &&
                    contactSearchData.map((item, index) => (
                      <>
                        {index < 3 && (
                          <div className="search-row">
                            <div className="srow">
                              <i className="fas fa-user"></i>
                              {item.matter_id ? (
                                <Link
                                  to={{
                                    pathname: `/claim/${item.matter_id}/contacts/2/searchTerm/${item.id}`,
                                    state: { normalRedirect: true },
                                  }}
                                  className="primary-link grey-link"
                                >
                                  <div className="sbind">
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item['full_name']}
                                    />
                                    &nbsp;
                                    <span className="htext">
                                      <Highlighter
                                        highlightClassName="highlightMatch"
                                        searchWords={[searchKeyword]}
                                        autoEscape={true}
                                        textToHighlight={item['primary_email_address']}
                                      />
                                    </span>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={`/global-contact/${
                                    item.primary_email_address
                                      ? item.primary_email_address
                                      : item.full_name
                                  }`}
                                  className="primary-link grey-link"
                                >
                                  <div className="sbind">
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item['full_name']}
                                    />
                                    &nbsp;
                                    <span className="htext">
                                      <Highlighter
                                        highlightClassName="highlightMatch"
                                        searchWords={[searchKeyword]}
                                        autoEscape={true}
                                        textToHighlight={item['primary_email_address']}
                                      />
                                    </span>
                                  </div>
                                </Link>
                              )}
                            </div>

                            <div className="stags">
                              {item.matchObj &&
                                Object.keys(item.matchObj).length !== 0 &&
                                Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                                  if (keyIndex < 3) {
                                    return (
                                      <span className="stag-li">
                                        {Capitalize(keyName.replace(/_/g, ' '))}:
                                        <Highlighter
                                          highlightClassName="highlightMatch"
                                          searchWords={[searchKeyword]}
                                          autoEscape={true}
                                          textToHighlight={item[keyName]}
                                        />
                                      </span>
                                    );
                                  }
                                  return '';
                                })}
                              {Object.keys(item.matchObj).length > 4 && (
                                <span className="stag-li">
                                  +{Object.keys(item.matchObj).length - 3}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  {contactSearchData &&
                    contactSearchData.length > 0 &&
                    postsPerPageContact !== 3 &&
                    contactSearchData.map((item, index) => (
                      <div className="search-row" key={index}>
                        <div className="srow">
                          <i className="fas fa-user"></i>
                          {item.matter_id ? (
                            <Link
                              to={`/claim/${item.matter_id}/contacts/2/searchTerm/${item.id}`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                <Highlighter
                                  highlightClassName="highlightMatch"
                                  searchWords={[searchKeyword]}
                                  autoEscape={true}
                                  textToHighlight={item['full_name']}
                                />
                                &nbsp;
                                <span className="htext">
                                  <Highlighter
                                    highlightClassName="highlightMatch"
                                    searchWords={[searchKeyword]}
                                    autoEscape={true}
                                    textToHighlight={item['primary_email_address']}
                                  />
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to={`/global-contact/${
                                item.primary_email_address
                                  ? item.primary_email_address
                                  : item.full_name
                              }`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                <Highlighter
                                  highlightClassName="highlightMatch"
                                  searchWords={[searchKeyword]}
                                  autoEscape={true}
                                  textToHighlight={item['full_name']}
                                />
                                &nbsp;
                                <span className="htext">
                                  <Highlighter
                                    highlightClassName="highlightMatch"
                                    searchWords={[searchKeyword]}
                                    autoEscape={true}
                                    textToHighlight={item['primary_email_address']}
                                  />
                                </span>
                              </div>
                            </Link>
                          )}
                        </div>
                        <div className="stags">
                          {item.matchObj &&
                            Object.keys(item.matchObj).length !== 0 &&
                            Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                              if (keyIndex < 3) {
                                return (
                                  <span className="stag-li">
                                    {Capitalize(keyName.replace(/_/g, ' '))}:
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item[keyName]}
                                    />
                                  </span>
                                );
                              }
                              return '';
                            })}
                          {Object.keys(item.matchObj).length > 4 && (
                            <span className="stag-li">
                              +{Object.keys(item.matchObj).length - 3}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}

                  {postsPerPageContact === 3 && contactSearchData && contact_count > 3 && (
                    // contactSearchData.length > 3
                    <span
                      className="primary-link flex flex-start ai-center sm-link ml-15"
                      onClick={() => {
                        setPostsPerPageContact(10);
                        if (contactSearchData?.length <= 3) {
                          getGlobalSearchData(searchKeyword, true);
                        }
                      }}
                    >
                      Show More <i className="fas fa-chevron-down"></i>
                    </span>
                  )}
                  {postsPerPageContact !== 3 && (
                    <span
                      className="primary-link flex flex-start ai-center sm-link ml-15"
                      onClick={() => {
                        setPostsPerPageContact(3);
                      }}
                    >
                      Show Less <i className="fas fa-chevron-up"></i>
                    </span>
                  )}
                </div>
                {/*  Documents */}
                <div className="search-block">
                  <small>Documents</small>
                  {/* list of contacts */}
                  {documentSearchData &&
                    documentSearchData.length > 0 &&
                    postsPerPageDocument === 3 &&
                    documentSearchData.map((item, index) => (
                      <>
                        {index < 3 && (
                          <div className="search-row">
                            <div className="srow">
                              {item.is_folder && <i className="fas fa-folder"></i>}
                              {!item.is_folder && <i className="fas fa-file-alt"></i>}
                              {item.folder ? (
                                <Link
                                  to={`/claim/${item.matter_id}/documents/${item.folder}`}
                                  className="primary-link grey-link"
                                >
                                  <div className="sbind">
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item['name']}
                                    />
                                    &nbsp;
                                    <span className="htext">
                                      <Highlighter
                                        highlightClassName="highlightMatch"
                                        searchWords={[searchKeyword]}
                                        autoEscape={true}
                                        textToHighlight={item['matter']}
                                      />
                                    </span>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={`/claim/${item.matter_id}/documents`}
                                  className="primary-link grey-link"
                                >
                                  <div className="sbind">
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item['name']}
                                    />
                                    &nbsp;
                                    <span className="htext">
                                      <Highlighter
                                        highlightClassName="highlightMatch"
                                        searchWords={[searchKeyword]}
                                        autoEscape={true}
                                        textToHighlight={item['matter']}
                                      />
                                    </span>
                                  </div>
                                </Link>
                              )}
                            </div>
                            <div className="stags">
                              {item.matchObj &&
                                Object.keys(item.matchObj).length !== 0 &&
                                Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                                  if (keyIndex < 3) {
                                    return (
                                      <span className="stag-li">
                                        {Capitalize(keyName.replace(/_/g, ' '))}:
                                        <Highlighter
                                          highlightClassName="highlightMatch"
                                          searchWords={[searchKeyword]}
                                          autoEscape={true}
                                          textToHighlight={item[keyName]}
                                        />
                                      </span>
                                    );
                                  }
                                  return '';
                                })}
                              {Object.keys(item.matchObj).length > 4 && (
                                <span className="stag-li">
                                  +{Object.keys(item.matchObj).length - 3}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  {documentSearchData &&
                    documentSearchData.length > 0 &&
                    postsPerPageDocument !== 3 &&
                    documentSearchData.map((item, index) => (
                      <div className="search-row" key={index}>
                        <div className="srow">
                          {item.is_folder && <i className="fas fa-folder"></i>}
                          {!item.is_folder && <i className="fas fa-file-alt"></i>}
                          {item.folder ? (
                            <Link
                              to={`/claim/${item.matter_id}/documents/${item.folder}`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                <Highlighter
                                  highlightClassName="highlightMatch"
                                  searchWords={[searchKeyword]}
                                  autoEscape={true}
                                  textToHighlight={item['name']}
                                />
                                &nbsp;
                                <span className="htext">
                                  <Highlighter
                                    highlightClassName="highlightMatch"
                                    searchWords={[searchKeyword]}
                                    autoEscape={true}
                                    textToHighlight={item['claim']}
                                  />
                                </span>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to={`/claim/${item.matter_id}/documents`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                <Highlighter
                                  highlightClassName="highlightMatch"
                                  searchWords={[searchKeyword]}
                                  autoEscape={true}
                                  textToHighlight={item['name']}
                                />
                                &nbsp;
                                <span className="htext">
                                  <Highlighter
                                    highlightClassName="highlightMatch"
                                    searchWords={[searchKeyword]}
                                    autoEscape={true}
                                    textToHighlight={item['claim']}
                                  />
                                </span>
                              </div>
                            </Link>
                          )}
                        </div>
                        <div className="stags">
                          {item.matchObj &&
                            Object.keys(item.matchObj).length !== 0 &&
                            Object.keys(item.matchObj).map(function (keyName, keyIndex) {
                              if (keyIndex < 3) {
                                return (
                                  <span className="stag-li">
                                    {Capitalize(keyName.replace(/_/g, ' '))}:
                                    <Highlighter
                                      highlightClassName="highlightMatch"
                                      searchWords={[searchKeyword]}
                                      autoEscape={true}
                                      textToHighlight={item[keyName]}
                                    />
                                  </span>
                                );
                              }
                              return '';
                            })}
                          {Object.keys(item.matchObj).length > 4 && (
                            <span className="stag-li">
                              +{Object.keys(item.matchObj).length - 3}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}

                  {postsPerPageDocument === 3 && documentSearchData && document_count > 3 && (
                    // documentSearchData.length > 3
                    <span
                      className="primary-link flex flex-start ai-center sm-link ml-15"
                      onClick={() => {
                        if (documentSearchData?.length <= 3) {
                          getGlobalSearchData(searchKeyword, true);
                        }
                        setPostsPerPageDocument(10);
                      }}
                    >
                      Show More <i className="fas fa-chevron-down"></i>
                    </span>
                  )}
                  {postsPerPageDocument !== 3 && (
                    <span
                      className="primary-link flex flex-start ai-center sm-link ml-15"
                      onClick={() => {
                        setPostsPerPageDocument(3);
                      }}
                    >
                      Show Less <i className="fas fa-chevron-up"></i>
                    </span>
                  )}
                </div>
                {/* all serach page */}
              </Scrollbar>
              <span className="btn-container">
                <Link to={`/global-search`}>
                  <Button>
                    All Search Result&nbsp;<i className="far fa-arrow-right"></i>
                  </Button>
                </Link>
              </span>
            </div>
          )}

        {/* recently viewd items here shown up */}
        {!searchKeyword && (
          <div className="search-list">
            <Scrollbar
              damping={5}
              thumbMinSize={80}
              syncCallbacks={true}
              alwaysShowTracks={true}
              continuousScrolling={true}
              style={{ maxHeight: '480px', width: '516px', overflowX: 'hidden' }}
              className="scrollblock grey-scroll md-scroll"
            >
              {/* Recently Viewed Claims */}
              <div className="search-block">
                <small>
                  Recently Viewed {login_user_role_type === 'law_firm' ? 'Matters' : 'Claims'}
                </small>
                {/* list of claims */}
                {claimRecentSearchData &&
                  claimRecentSearchData.length > 0 &&
                  claimRecentSearchData.map((item, index) => (
                    <div className="search-row" key={index}>
                      <div className="srow">
                        <i className="fas fa-gavel"></i>
                        <Link to={`/claim/${item.id}/overview`} className="primary-link grey-link">
                          <div className="sbind">
                            {' '}
                            {item.claim_name}
                            &nbsp;
                            <span className="htext">{item.claim_number}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Recently Viewed Contacts */}
              <div className="search-block">
                <small>Recently Viewed Contacts</small>
                {/* list of contacts */}
                {contactRecentSearchData &&
                  contactRecentSearchData.length > 0 &&
                  contactRecentSearchData.map((item, index) => (
                    <div className="search-row" key={index}>
                      <div className="srow">
                        <i className="fas fa-user"></i>
                        <Link
                          to={`/global-contact/${
                            item.primary_email_address ? item.primary_email_address : item.full_name
                          }`}
                          className="primary-link grey-link"
                        >
                          <div className="sbind">
                            {item.full_name}
                            &nbsp;
                            <span>{item.primary_email_address}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Recently Viewed Documents */}
              <div className="search-block">
                <small>Recently Viewed Documents</small>
                {/* list of contacts */}
                {documentRecentSearchData &&
                  documentRecentSearchData.length > 0 &&
                  documentRecentSearchData.map((item, index) => (
                    <div className="search-row" key={index}>
                      <div className="srow">
                        {item.is_folder && item.folder ? (
                          <React.Fragment>
                            <i className="fas fa-folder"></i>
                            <Link
                              to={`/claim/${item.matter_id}/documents/${item.folder}`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                {item.name}
                                &nbsp;
                                <span>{item.claim}</span>
                              </div>
                            </Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <i className="fas fa-file-alt"></i>
                            <Link
                              to={`/claim/${item.matter_id}/documents`}
                              className="primary-link grey-link"
                            >
                              <div className="sbind">
                                {item.name}
                                &nbsp;
                                <span>{item.claim}</span>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </Scrollbar>
          </div>
        )}
      </Menu.Item>
    </Menu>
  );

  const markExportDownloaded = async id => {
    try {
      const res = await api.current.backgroundExportsUpdate(id, {
        is_exported: true,
      });
      if (res.status === 200) {
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        icon: <i className="fas fa-times-circle"></i>,
        className: 'error-notification',
        message: 'Error',
        description: 'Something went wrong',
      });
    }
  };

  const [pendingDownloads, setPendingDownloads] = useState([]);

  const handleDownloadPendingFiles = async pendingFiles => {
    pendingFiles.map(async (item, index) => {
      const file_name = item.file.split('/').pop().split('?')[0];
      downloadFile(item.file, file_name);
      setPendingDownloads(prev => prev.filter(pendingItem => pendingItem.id !== item.id));
      await markExportDownloaded(item.id);
    });
  };

  useEffect(() => {
    let _interval = setInterval(async () => {
      let res = await api.current.backgroundExports({
        is_exported: false,
      });
      if (res.status === 200) {
        let pendingItems = res.data.filter(item => item.task_status === 'in_progress');
        let completedFiles = res.data.filter(item => item.task_status === 'completed');
        setPendingDownloads(completedFiles);
        setInProgressBGTasks(pendingItems);
      }
    }, INTERVALS.THREE_SECONDS);

    return () => {
      clearInterval(_interval);
    };
  }, []);

  // Main Render Method
  return (
    <>
      {/* Component loader */}
      {tableDataLoading && <TableLoader className="global-loader" />}
      {on_trial && (
        <div
          className={
            on_trial
              ? 'trial-expiry d-flex flex-center ai-center'
              : 'trial-expiry d-flex flex-center ai-center'
          }
        >
          <img className="triangleexclamation-img mr-5" src={triangleexclamation} alt="" /> Your
          trial will expire in {trial_days} days
        </div>
      )}

      <div className="header flex ai-center">
        <div className="hleft flex ai-center">
          <div className="logo">
            <Link to={'/global-claim'}>
              <img src={logo} alt="logo" width="42" />
            </Link>
          </div>
          <div className="search-wrapper fitem-rinner">
            <Dropdown
              visible={openMenuSearchFlag}
              // visible={true}
              getPopupContainer={triggerNode => triggerNode}
              onVisibleChange={flag => {
                setOpenMenuSearchFlag(flag);
                if (flag && !searchKeyword) {
                  getRecentSearchData();
                }
              }}
              overlay={menuGlobalSearch}
              // trigger={['hover', 'click']}   fix SY-2002 bug
              trigger={['hover']}
              overlayClassName="search-dropdown"
              // visible={true}
            >
              <Input
                placeholder={
                  login_user_role_type === 'law_firm'
                    ? 'Search any matters, contacts, or documents'
                    : 'Search any claims, contacts, or documents'
                }
                allowClear={true}
                value={searchKeyword}
                onChange={e => {
                  setSearchKeyword(e.target.value);
                  if (e.target.value.length === 0) {
                    dispatchCall(
                      globalSearch({
                        search_keyword: e.target.value,
                        total_count: 0,
                        claim_count: 0,
                        contact_count: 0,
                        document_count: 0,
                      })
                    );
                    getGlobalSearchData('');
                    getRecentSearchData();
                  }
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    props.history.push(`/global-search`);
                  }
                }}
                onKeyUp={e => {
                  e.persist();

                  if (timer) {
                    window.clearTimeout(timer);
                  }
                  // Make a new timeout set to go off in 1000ms (1 second)
                  setTimer(
                    setTimeout(function () {
                      if (e.target.value.length >= 2) {
                        // setMenuSearchDataLoading(true);
                        getGlobalSearchData(e.target.value);
                      }
                    }, 500)
                  );
                }}
              />
            </Dropdown>
            {searchKeyword && searchKeyword !== '' && (
              <div className="fitem-ricon">
                <Link to={`/global-search`}>
                  <button type="button" className="btn">
                    <i className="far fa-search disable"></i>
                  </button>
                </Link>
              </div>
            )}
          </div>
          {user_type_data === 'admin' && (
            <div className="ddown">
              <Dropdown
                className="bdropdown"
                overlay={menuGlobalActions}
                overlayClassName="bdropdown"
              >
                <Button className="btn blue-btn lg-btn" style={{ width: '140px' }}>
                  Add New &nbsp; <i className="fas fa-sort-down"></i>
                </Button>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="hright flex ai-center">
          <div
            className={!IsNotificationDrawerShow ? 'notification' : 'notification active'}
            onClick={() => {
              getNotifications();
              setIsNotificationDrawerShow(true);
              if (churnZeroData?.churnInfo) {
                churnZeroData?.churnInfo.trackEvent(
                  `Viewed Tasks & Requests Notifications`,
                  `User clicked on Notification Bell`
                );
              }
            }}
          >
            <i className="fas fa-bell"></i>
            {glabal_notification_available && <span className="ndot"></span>}
          </div>
          <div className="user-ddown">
            <Dropdown dropdownClassName="head-ddown" overlay={menu}>
              <Button>
                <span className="username" title={first_name + ' ' + last_name}>
                  <span className="ellipsis-text">
                    Hi, {first_name} &nbsp;{last_name}
                  </span>
                  <small className="ellipsis-text">{company_name}</small>
                </span>
                <i className="fas fa-sort-down"></i>
              </Button>
            </Dropdown>
            <div className="profile-wrap">
              <figure>
                <img src={profile_pic && profile_pic !== '' ? profile_pic : DefaultImage} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      {/* Global Bell */}
      {IsNotificationDrawerShow && (
        <SelectDrawer
          onCloseDrawer={onCloseNotificationDrawer}
          visible={IsNotificationDrawerShow}
        />
      )}
      {/* Global Claim Messenger Floating Button */}
      {ShowUserTypeDrop && messenger_group_url !== '' && (
        <Link to={`/messenger/group/${messenger_group_url}`}>
          <div className="newmsg">
            <i className="fas fa-comments-alt"></i>
            {showDot && <div className="ndot"></div>}
          </div>
        </Link>
      )}
      {/* create task and request modal */}
      {showCreateTaskModal && (
        <CreateTaskModal
          visible={showCreateTaskModal}
          metaData={metaData}
          authoritySelected={authoritySelected}
          showDuplicate={false}
          onClose={(flag, type) => {
            if (flag) {
              if (type === 'duplicate') {
                setShowCreateTaskModal(true);
              } else {
                setShowCreateTaskModal(false);
              }
            } else {
              setShowCreateTaskModal(false);
            }
            setAuthoritySelected(false);
          }}
        ></CreateTaskModal>
      )}
      {/* create event modal */}
      {showEventModal && (
        <CreateEventModal visible={showEventModal} onClose={() => setShowEventModal(false)} />
      )}
      {/* add contact */}
      {showAddPerson && (
        <AddContact
          visible={showAddPerson}
          onClose={flag => {
            if (flag) {
              dispatchCall(GlobalContact(!isContactAdded));
            }
            setShowAddPerson(false);
          }}
        />
      )}
      {/* add contact */}
      {/* add company */}
      {showAddCompany && (
        <AddCompany
          visible={showAddCompany}
          onClose={flag => {
            if (flag) {
              dispatchCall(GlobalContact(!isContactAdded));
            }
            setShowAddCompany(false);
          }}
        />
      )}
      {/* TODO: update styling using class based components  */}
      {/* Global Export Background Processes */}
      <div className="notification-grouping">
        {inProgressBGTasks.length > 0 && (
          <>
            {/* left side fixed div with loader and text */}
            <div className="notify-sec">
              <div className="ntico d-flex h-100 ai-center">
                <i className="fas fa-file-download" style={{ fontSize: '20px' }} />
                {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> */}
                <span className="wrap-text text-left ml-10 mr-30">
                  {inProgressBGTasks.length} export(s) in progress...
                </span>
              </div>
              <img src={loader} alt="loader" height="30" />
              {/* show spinner with current */}
            </div>
          </>
        )}
        {pendingDownloads.length > 0 && (
          <div className="notify-sec">
            <div className="ntico d-flex h-100 ai-center">
              <i className="fas fa-file-download" style={{ fontSize: '20px' }} />
              <span className="wrap-text text-left ml-10">
                {pendingDownloads.length} export(s) is ready to download.{' '}
                <a onClick={() => handleDownloadPendingFiles(pendingDownloads)} className="ml-10">
                  <u>click here</u>
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
      {/* show read for download file list along with download button */}
    </>
  );
}

// export default withRouter(MainHeader);
export default withRouter(
  withSendBird(MainHeader, state => {
    return {
      sdk: sendBirdSelectors.getSdk(state),
      Channel: Channel,
    };
  })
);
